import React, { useState, useRef } from 'react'
import Logo from '../assets/images/logo.png'
import { Link } from "react-router-dom";
import StickyHeader from './StickyHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import cartImg from '../assets/images/cart_ico.svg'
import { useAuth } from '../context/AuthContext';
import CommonApis from '../queries/common';
import emptyNotiImg from '../assets/images/noti_empty-removebg-preview.png'
import profilePic from '../assets/images/profilepic.png'
function Header({ userDetails }) {
    const headerRef = useRef(null);
    const isSticky = StickyHeader(headerRef);
    const { pathname } = useLocation()
    const { isAuthenticated, logout } = useAuth()
    const { data: notifications, refetch: notificationRefetch } = CommonApis.GetNotifications(3, isAuthenticated)
    const readAllnotification = CommonApis.ReadAllNotificatio()
    console.log("==notification===", notifications)
    const navigate = useNavigate()
    const publicHeader = [
        {
            title: "Home",
            value: "home",
            path: '/'
        },
        {
            title: "Categories",
            value: "category",
            path: '/categories'
        },
        {
            title: "Course",
            value: "course",
            path: '/courses'
        },
        {
            title: "Contact Us",
            value: "contact-us",
            path: '/contact-us'
        },
    ]

    const signoutHandler = () => {
        logout()
        navigate('/')
    }
    const viewAllnotification = async () => {
        // const notificationTabButton = document.querySelector("#nav-tab6-tab");
        // if (notificationTabButton) {
        //     notificationTabButton.click();
        // }
        // const response = await readAllnotification.mutateAsync()
        // if (response.status) {
        //     await notificationRefetch()
        // }
        navigate('/notifications')
    }
    const readSingleNoti = async (id) => {
        const response = await readAllnotification.mutateAsync({ notification: id })
        if (response.status) {
            await notificationRefetch()
        }
    }
    return (
        <header ref={headerRef} className={`p-3 main_header ${isSticky ? 'fixed' : ''}`}>
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <a href="index.html" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
                        <img src={'/images/CareTeam-Logo-Final-1_page-0001-removebg-preview.png'} alt="" style={{width:'70px'}}/>
                    </a>

                    <ul className="nav col-12 col-lg-auto m-auto mb-2 justify-content-center mb-md-0">
                        {
                            publicHeader.map((item, index) => (
                                <li key={index}>
                                    <Link
                                        to={item.path}
                                        className={`nav-link px-2 ${item.path === pathname ? 'active' : 'link-dark'}`}
                                    >
                                        {item.title}
                                    </Link>
                                </li>
                            ))
                        }

                    </ul>

                    {/* <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3 search-header">
                        <i className="fa fa-search search-ico"></i>
                        <input type="search" className="form-control" placeholder="Search..." aria-label="Search" />
                    </form> */}

                    <div className="col-md-3 text-end d-flex gap-3 align-items-center justify-content-end ">
                        {
                            isAuthenticated &&
                            <div className="dropdown d-inline-block noti-container">

                                <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="notification" data-bs-toggle="dropdown" aria-expanded="false">
                                    <button className='notification-count'>{notifications?.data?.count}</button>
                                    <i className="fa-regular fa-bell"></i>
                                </a>
                                <ul className="dropdown-menu text-small notification_list p-2" aria-labelledby="notification">
                                    {
                                        notifications?.data?.notifications.length > 0 ?
                                            notifications?.data?.notifications.map((item, index) => {
                                                return (
                                                    <li className="p-1" onClick={() => readSingleNoti(item.id)}>
                                                        <a href="#">
                                                            <strong>{item.type}</strong>
                                                            <p>{item.message}</p>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                            :
                                            <li>
                                                <center>
                                                    <img src={emptyNotiImg} className='w-50' />
                                                    <h6>No new notifications</h6>
                                                </center>

                                            </li>

                                    }
                                    {
                                        notifications?.data?.notifications.length > 0 &&
                                        <li className="p-1 noti-view-all" onClick={viewAllnotification}><span>View All</span>
                                        </li>
                                    }

                                </ul>
                            </div>
                        }

                        {
                            isAuthenticated &&
                            <>
                                <Link className="top_header_ico position-relative" to='/cart'><button className='cart-count'>{userDetails?.cartItems}</button><img src={cartImg} alt="" /></Link>
                                {/* <div className="dropdown d-inline-block">
                                    <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="lang" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-globe"></i> En
                                    </a>
                                    <ul className="dropdown-menu text-small" aria-labelledby="lang" >
                                        <li><a className="dropdown-item" href="#">English</a></li>
                                    </ul>
                                </div> */}
                                </>

                        }

                        {
                            isAuthenticated ?
                                <div className="dropdown text-end">
                                    <a
                                        href="#"
                                        className="d-block link-dark text-decoration-none dropdown-toggle"
                                        id="dropdownUser1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            src={userDetails?.user_detail?.profilePhoto??profilePic}
                                            alt="mdo"
                                            width="32"
                                            height="32"
                                            className="rounded-circle"
                                        />
                                        <span className="userNameTop">{userDetails?.username}</span>
                                    </a>
                                    <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                                        <li>
                                            <Link className="dropdown-item" to='courses'>
                                                Courses
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to='/my-account'>
                                                My Account
                                            </Link>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={signoutHandler} href="#">
                                                Sign out
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                :
                                <Link to='login' className="btn btn-primary">Login / Register</Link>
                                // <Link to='login' className="btn btn-primary">Login</Link>

                        }

                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header