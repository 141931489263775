import React from 'react'
import bannerImg from '../../assets/images/banner-image-banner.png'
import { Link } from 'react-router-dom'
function Banner() {
    return (
        <section className="banner_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">

                        <h1>
                            Welcome to the <strong><span style={{ color: '#e77324' }}>Care Team Academy</span></strong>
                            <strong> From The <span>Experts</span></strong>
                        </h1>
                        <p>
                            Unleashing Potentials, One Code at a Time
                            This is your dedicated space for growth, knowledge, and transformation. Whether you're here to enhance your skills, explore training programs, or access recorded sessions, you're in the right place.
                        </p>
                        <div className="d-flex gap-3">
                            <Link to='courses' className="btn btn-primary">
                                View All Course <i className="fa-solid fa-arrow-right"></i>
                            </Link>
                            <Link to="login" className="btn btn-secondary">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-md-6 banner-image">
                        <img src={bannerImg} alt="" />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Banner