import React from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import CourseApis from '../../queries/course'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
function Categories() {
    const { data: Categories,isLoading } = CourseApis.GetCategories()
    
    const navigate = useNavigate()
    const navigateHandler = (catId) => {
        navigate('/courses', { state: { category: catId } })
    }
    return (
        <div>
            <InnerPageHeader
                title="Categories"
                subtitile="My Account"
                titlePath="categories"
                subTitlePath="my-account"
            />
            <section className="home_category_section">
                <div className="container">
                    <div className="main_heading_txt">
                        <div className="row align-items-center">
                            <div className="col-md-6" data-aos="fade-up">
                                <h2>
                                    Explore Our<br />
                                    <strong> Categories</strong>
                                </h2>
                            </div>
                            <div className="col-md-6">
                                <p>
                                Discover a platform that connects passion with purpose. Gain the skills you need to thrive in today’s ever-changing world.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                             isLoading ?
                             Array.from({ length: 4 }).map((_, index) => (
                                 <div className="col-md-3" data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}>
                                     <div className="category_box">
                                         <div className="category_box_ico"><Skeleton circle={true} width={50} height={50}/></div>
                                         <div className="category_box_cnt_skeleton">
                                             <h3><Skeleton/></h3>
                                             <p><Skeleton count={3}/></p>
                                         </div>
                                     </div>
                                 </div>
                             ))
                             :
                            Categories?.data &&
                            Categories?.data.map((item, index) => {
                                return (
                                    <div className="col-md-3" data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}>
                                        <a href="#">
                                            <div className="category_box" onClick={()=>navigateHandler(item.id)}>
                                                <div className="category_box_ico"><i className={item.icon??'fa-solid fa-chart-simple'}></i></div>
                                                <div className="category_box_cnt">
                                                    <strong>{item.name}</strong>
                                                    <span>{item.description}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Categories